import React, { Component } from "react"
import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import HeaderAndText from "@components/header_with_text"
import BackButton from "@components/back_button"
import FormButton from "@components/_atoms/button_form_input_submit"
import * as progress from "@services/progress"
import ProgressBar from "@components/_atoms/progress_bar_reg"

const isClient = typeof window !== "undefined"

class AlmostThere extends Component {
  constructor(props) {
    super(props)
    this.state = {
      buttonSubmitted: false
    }
  }

  handleClick = () => {
    this.setState({ buttonSubmitted: true })
    progress.updateProgress()
    setTimeout(function() {
      if (isClient) {
        window.location = `/pledge/register/${progress.getNext()}`
      }
    }, 3000)
  }

  render() {
    const { almostThere } = data
    return (
      <Layout pageClass="pledge" type="light">
        <SEO title={almostThere.title} />
        <ProgressBar />
        <BackButton data={almostThere.backLink} />
        <HeaderAndText data={almostThere.heading} />
        <div style={{ height: `8rem`, width: `100%` }} />
        <div onClick={this.handleClick.bind(this)}>
          <FormButton isDisabled={this.state.buttonSubmitted} />
        </div>
      </Layout>
    )
  }
}

export default AlmostThere
